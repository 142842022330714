<template>
  <div>
    <ClientHeader :client="client" />
    <v-row>
      <v-col>
        <v-btn @click="handleNewAppealClick" color="primary">Start New Appeal</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" v-for="x in appeals">
        <v-card @click="handleRowClick(x)" style="cursor:pointer; margin-bottom: 10px">
          <v-card-title>
            Appeal for
          </v-card-title>
          <v-card-text>
            <p class="font-weight-bold text" v-if="x.denial_year && x.denial_quarter"> {{x.denial_year}} - Q{{x.denial_quarter}}</p>
            <p v-else class="font-weight-bold">No Year/Quarter set yet</p>
          </v-card-text>
        </v-card>
      </v-col>
<!--      <v-col>-->
<!--        <v-data-table-->
<!--          @click:row="handleRowClick"-->
<!--          :headers="tableHeaders"-->
<!--          :items="appeals"-->
<!--          :loading="appealsLoading"-->
<!--          :items-per-page="5"-->
<!--          class="elevation-1 row-pointer"-->
<!--          no-data-text="There are no Appeals yet."-->
<!--          loading-text="Loading Appeals"-->
<!--        />-->
<!--      </v-col>-->
    </v-row>
  </div>
</template>

 <script>
  import ClientHeader from "@/components/Client/ClientHeader.vue";
  import ClientService from "@/services/clients.service";
  import AppealsService from "@/services/appeals.service";

  export default {
    name: "Appeals",
    components: { ClientHeader },
    data() {
      return {
        client: null,
        clientId: undefined,
        appealsLoading: false,
        appeals: [],
        tableHeaders: [
          {
            text: 'ID',
            value: 'appeal_id'
          },
          {
            text: 'Quarter',
            value: 'quarter'
          },
          {
            text: 'Year',
            value: 'year'
          },
          {
            text: 'Status',
            value: 'appeal_status'
          }
        ]
      };
    },
    methods: {
      getCurrentClient(){
        ClientService.getOneClient(this.clientId).then(res=>{
          this.client = res
        })
      },
      handleRowClick(row) {
        const appealId = row.appeal_id

        this.$router.push(`/clients/${this.clientId}/appeals/${appealId}`)
      },
      handleNewAppealClick() {
        this.$router.push(`/clients/${this.clientId}/appeals/new`)
      },
      async getAppeals() {
        this.appealsLoading = true

        this.appeals = await AppealsService.getClientAppeals(this.clientId)

        this.appealsLoading = false
      }
    },
    created() {
      this.clientId = this.$route.params.clientId;
      this.getAppeals()

      this.getCurrentClient()
    },
  };
</script>

<style scoped>
  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }
</style>
